

<template>
  <div>
    <div class="row">
       <div class="col-md-12">
         <div class="card">
           <div class="card-body">
              <h3>Order Number: <strong class="text-muted">{{ order.OrderNumber }}</strong></h3>
              <h4>Status:  
                 <strong class="text-primary" v-if="order.orderStatus=='Accepted'">{{ order.orderStatus }}</strong>
                 <strong class="text-muted" v-if="order.orderStatus=='Submitted'">{{ order.orderStatus }}</strong>
                 <strong class="text-danger" v-if="order.orderStatus=='Rejected'">{{ order.orderStatus }}</strong>
              </h4>
              <div class="my-2">
                <span
                  >Sent on {{ $date_format.date_only(order.doneAt) }} ({{
                    $date_format.from_now(order.doneAt)
                  }})</span
                >
              </div>
              <div class="row mt-2">
                <div class="col-md-12">
                  <button class="btn btn-sm btn-outline-primary" v-if="order.orderStatus=='Submitted'" @click="acceptOrder">Mark as Accepted</button>
                  <button class="btn btn-sm btn-outline-warning" v-if="order.orderStatus=='Submitted'" @click="rejectOrder">Mark as Rejected</button>
                </div>
              </div>
              <hr/>
              <div class="row">
                <div class="col-md-12"><strong>Customer Contacts</strong></div><br/>
    
                <div class="col-md-3">
                    <table class="table table-hover">
                    <tbody>
                      <tr>
                        <td>Name:</td>
                        <td>{{ customer.firstName }} {{ customer.lastName }}</td>
                      </tr>
                    </tbody>
                    </table>
                </div>
                <div class="col-md-3">
                    <table class="table table-hover">
                    <tbody>
                      <tr>
                        <td>Phone:</td>
                        <td>{{ customer.phone}}</td>
                      </tr>
                    </tbody>
                    </table>
                </div>
                <div class="col-md-12"></div>
                <div class="col-md-3">
                    <table class="table table-hover">
                    <tbody>
                      <tr>
                        <td>Email:</td>
                        <td>{{customer.email}}</td>
                      </tr>
                    </tbody>
                    </table>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-md-12 mb-3"><strong>Ordered Product details</strong></div>
              
                <div class="col-md-4">
                   <order-component :order="order" :backend_url="backend_url" />
                </div>
                <div class="col-md-8">
                      <label><em class="fa fa-comment"></em> <strong class="text-muted">Client Order Description</strong> </label>
                      <textarea class="form-control"  v-if="order.order_comment" v-model="order.order_comment" disabled> </textarea>
                </div>
              </div>
              
           </div>
         </div>
       </div>
    </div>
  </div>
</template>
<script>
// import DataTable from "@/components/Datatable";
import axios from "axios";
import { alert } from "../../utils/alertUtils";
import OrderComponent from "../../components/OrderComponent";

export default {
  components: {
    // "data-table": DataTable,
    "order-component": OrderComponent,
  },
  data() {
    return {
      backend_url: process.env.VUE_APP_BACKEND_URL,
      data_loaded: false,
      additional_info_data: [],
      sub_total_amount: 0,
      data: {},
      entity: {},
      customer: {},
      errors: {
        description: [],
      },
      order: {},
    };
  },

  methods: {
    prompt_sendPayment: function () {
      alert.promptSure(this.sendPaymentRequest);
    },
    sendPaymentRequest: function () {
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/api/orders/${this.$route.params.uuid}/send_payment_request`,
          {
            headers: {
              Authorization: "Bearer " + this.$store.getters.getToken,
            },
          }
        )
        .then((res) => {
          console.log(res);
          this.fetch_order();
          alert.success("Payment request successfully sent!");
        })
        .catch((err) => {
          console.log(err.response);
          alert.error("Sending payment request failed, try again!");
        });
    },
    add_additionalInfo: function () {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/order_more_infos`;
      var data = {
        title: this.data.title,
        description: this.data.description,
        amount: this.data.amount,
        order_uuid: this.$route.params.uuid,
      };

      axios
        .post(url, data, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then(() => {
          alert.success_center("additional info added successfully");
          document.getElementById("cancelbtn").click();
          this.data = {};
          this.fetch_order();
        })
        .catch((err) => {
          alert.error("an error occured in adding additional information");
          console.log(err.response);
        });
    },
    show_additional_Info_detail: function (uuid) {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/order_more_infos/${uuid}`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          alert.info_center(res.data.description);
        })
        .catch((err) => {
          console.log(err.response);
          // alert.error('an error occured in getting add info')
        });
    },
    delete_additional_info: function (uuid) {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/order_more_infos/${uuid}`;
      axios
        .delete(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then(() => {
          alert.success_center("additional info deleted successfully");
          this.fetch_order();
        })
        .catch((err) => {
          alert.error("an error occured in deleting additional information");
          console.log(err.response);
        });
    },
    fetch_order: function () {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/orders/${this.$route.params.uuid}`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          console.log(res.data);
          this.order = res.data;
          this.entity = res.data.order_items[0].entity;
          this.customer = res.data.customer;
          this.additional_info_data = res.data.additional_items;
          this.data_loaded = true;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    cancel_order: function () {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/orders/${this.$route.params.uuid}/cancel/`;
      axios
        .put(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          console.log(res);
          alert.success("you have successfully cancered order");
          this.fetch_order();
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    show_help_add_addInfo: function () {
      alert.info_center(
        "Add some additional fees,title and description of additional fees  in case there are some additional fees on this order, Example:Taxes, Delivery fees, ..."
      );
    },

    show_help_mark_as_complete: function () {
      alert.info_center(
        "Please, Only Mark the order as paid only if you have successfully received money for this order, Example: when the customer has payed by bank transfer, ..., if the customer has used other payment method like Mobile Money, credit card, ... you do not have to use this option because the system will change the payment status automatically."
      );
    },
    prompt_cancel_order: function () {
      alert.promptSure(this.cancel_order);
    },
    prompt_mark_as_paid_order: function () {
      alert.promptSure(this.mark_order_as_paid);
    },

    mark_order_as_paid: function () {
      var url = `${process.env.VUE_APP_BACKEND_URL}/api/orders/${this.$route.params.uuid}/mark_as_paid/`;
      axios
        .put(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          console.log(res);
          alert.success("You have successfully Marked this order as Paid");
          this.fetch_order();
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
     
    acceptOrder:function(){
      alert.promptSure(this.confirmAcceptOrder)
    } ,
    confirmAcceptOrder:function(){
         var url = `${process.env.VUE_APP_BACKEND_URL}/api/orders/${this.$route.params.uuid}/mark_as_accepted/`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          console.log(res);
          alert.success("Order is successfuly marked as accepted");
          this.fetch_order();
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    rejectOrder:function(){
      alert.promptSure(this.confirmRejectOrder)
    },
    confirmRejectOrder:function(){
            var url = `${process.env.VUE_APP_BACKEND_URL}/api/orders/${this.$route.params.uuid}/mark_as_rejected/`;
      axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.getToken,
          },
        })
        .then((res) => {
          console.log(res);
          alert.success("Order is successfuly is rejected");
          this.fetch_order();
        })
        .catch((err) => {
          console.log(err.response);
        });
    } 

  },
  created() {
    this.fetch_order();
  },
};
</script>
<style lang="css">
#additionalInfotable_length {
  display: none;
}
#additionalInfotable_info {
  display: none;
}
#additionalInfotable_paginate {
  display: none;
}
</style>