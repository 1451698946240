<template>
  <div>
    <div
      class="card card-shadow-lg p-2 mb-3 shadow--hover cart-card"
      v-for="item in order.order_items"
      :key="item.uuid"
    >
      <div class="row">
        <div class="col-md-12">
          <img
            :src="backend_url + item.product_unit.product.primeImageUrl"
            alt
            srcset
          />
        </div>
        <div class="col-md-12">
           <div> 
             <div>Product Name:<strong>{{ item.product_unit.product.name }}</strong></div>
             <div>Price 
                 {{
              $num_format(item.total, {
                op_AddSpaceToDelimiter: true,
              })
            }}
            Rwf
             </div>
           </div>
            
        </div>
       
      </div>
    
    </div>
  </div>
</template>
<script>
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
    backend_url: {
      type: String,
      required: true,
    },
  },
  computed: {
    data() {
      return {
        // backend_url: process.env.VUE_APP_BACKEND_URL,
      };
    },
  },
};
</script>
<style lang="css" scoped>
</style>